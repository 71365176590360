<template>
    <div class="modal fade" id="modalProcedimientoPrograma" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Procedimientos</h5>
                    <button type="button" class="close" @click="closeModal()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning mt-2" v-if="!procedimientos.length">
                        Sin procedimientos predefinidos...
                    </div>
                    <div v-else class="card mt-2 border-gray-500 shadow-none card-header-actions" >
                       <div class="card-header">
                            Listado de Procedimientos
                            <div>
                                <span class="font-weight-bold text-primary">Seleccionar todo</span>
                                <label class="switchBtn mb-0 mx-1">
                                    <input type="checkbox" @click="selectedAllExams()">
                                    <div class="slide round"></div> 
                                </label>
                        </div>
                       </div>
                        <div class="card-body" >
                            <div class="row">
                                <div class="col-md-8 col-sm-12 col-lg-12 font-weight-bold" v-for="item in procedimientos" :key="item.id">
                                    <div class="form-row">
                                        <div class="form-group col-md-6">
                                            <label class="switchBtn mb-0 ">
                                                <input type="checkbox" v-model="item.checked" :value="item.id" >
                                                <div class="slide round"></div> 
                                            </label>
                                            <span class="text-blue"> {{ item.servicio.codigo }} </span>
                                            <i class="font-weight-600 text-uppercase">{{ item.descripcion }}</i>
                                        </div>
                                        <div class="form-group col-md-2">
                                            <label  class="form-label">Cantidad <small class="text-danger">*</small></label>
                                            <input type="number" v-model="item.cantidad" value="1" class="form-control form-control-sm">
                                        </div>
                                        <div class="form-group col-md-4">
                                            <label  class="form-label">Observacion <small class="text-danger">*</small></label>
                                            <input type="text" class="form-control form-control-sm"  v-model="item.observacion">
                                        </div>
                                       
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-success btn-sm" @click="proceduresSelected()">Añadir Procedimientos</button>
                    <button type="button" class="btn btn-light btn-sm" @click="closeModal()"><i class="far fa-times-circle fa-fw"></i> Cerrar</button>
                </div>
                </div>
            </div>
        </div>
</template>
<script>
import $ from 'jquery';
export default {
    props: ['procedimientos','usuario'],
    methods:{
        selectedAllExams(){
            this.procedimientos.forEach((e) =>{
                return e.checked?e.checked=false:e.checked=true;
            })
        },
        proceduresSelected(){
            let proccedures = [];

            proccedures = this.procedimientos.filter((x) => x.checked);
            
            this.$emit("cargar-procedimientos", proccedures);
            $('#modalProcedimientoPrograma').modal('hide');
        },
        closeModal(){
            $('#modalProcedimientoPrograma').modal('hide');
        }
    },
    computed: {
        user() {
            return this.$store.state.user;
        },   
    },
}
</script>
<style scoped>
.w-10{
    width: 10%;
}

.w-5{
    width: 5%;
}

/*toogle checkbox*/
.switchBtn {
    position: relative;
    display: inline-block;
    width: 35px;
    height: 18px;
}
.switchBtn input {display:none;}
.slide {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
    padding: 8px;
    color: #fff;
}
.slide:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 18px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}
input:checked + .slide {
    background-color: #06a7ed;
    padding-left: 20px;
}
input:focus + .slide {
    box-shadow: 0 0 1px #01aeed;
}
input:checked + .slide:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
    left: -20px;
}
.slide.round {
    border-radius: 24px;
}
.slide.round:before {
    border-radius: 50%;
}

</style>
