<template>
    <main>
        <!-- Modal -->
        <modalFormulasComponent :formulas="formulas" :usuario="usuario" ></modalFormulasComponent>
        <div class="card card-header-actions shadow-none border-0" >
            <div class="card-header">
                <h4 class="card-title mb-0">Nueva Formula</h4>
                <!-- <div class="col-4">
                    <label class="toggle" v-if="tipo_historia==='URG'">
                        <input class="toggle-checkbox" type="checkbox" v-model="interna" >
                        <div class="toggle-switch"></div>
                        <span class="toggle-label  text-primary">Orden Interna</span>
                    </label>
                </div> -->
                <button @click="historialFormulas();" type="button" class="btn btn-outline-primary btn-sm"><i class="fas fa-eye fa-fw"></i>Ver Formula</button>
            </div>
            <div class="card-body ">                
                <div class="form-group mt-2">
                    <label  class="form-label">Medicamento </label>  
                    <v-select-servicio v-model="item.servicio" mode="object"  :groups="[12,13]"
                    :class="{'is-error' : $v.item.servicio.$error}">
                    </v-select-servicio>
                    <span v-if="!$v.item.servicio.required && $v.item.servicio.$dirty" class="text-danger">
                        <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                    </span>
                </div>
                <div class="row">
                    <div class="form-group col-md-12 col-lg-12 col-sm-12 col-xs-12">
                        <label class="form-label small mb-0">Forma farmaceutica <small class="text-danger">*</small></label>
                        <select class="form-control form-control-sm" v-model="item.presentation"
                        :class="{'is-invalid':$v.item.presentation.$error && $v.item.presentation.$dirty}">
                            <option :value="presentation" v-for="(presentation,index) in presentations" :key="index">{{presentation}}</option>
                        </select>
                    </div>
                </div>

                <div class="row mx-0">
                   
                    <div class="card-header col-lg-12 col-sm-12 mb-3">Posologia</div>

                    <div class="form-group  col-md-12 col-lg-2 col-sm-12 col-xs-12 "  >
                        <label class="form-label small mb-0">Cantidad <small class="text-danger">*</small></label>
                        <input type="number" class="form-control form-control-sm" v-model="item.dosis" :class="{'is-invalid':$v.item.dosis.$error && $v.item.dosis.$dirty}">
                    </div>
                    <div class="form-group col-md-12 col-lg-2 col-sm-12 col-xs-12">
                        <label class="form-label small mb-0">U. medida <small class="text-danger">*</small></label>
                        <select @change="actualizarUnidadMedida" class="form-control form-control-sm" v-model="item.unity"
                        :class="{'is-invalid':$v.item.unity.$error && $v.item.unity.$dirty}">
                            <option :value="measurement" v-for="(measurement,index) in measurement_units" :key="index">{{measurement}}</option>
                        </select>
                    </div>
                    
                    <div class="form-group col-md-12 col-lg-3 col-sm-12 col-xs-12">
                        <label class="form-label small mb-0">Cada <small class="text-danger">*</small></label>
                        <div class="input-group">
                            <input type="number" v-model="item.frecuencia" class="form-control form-control-sm " :class="{'is-invalid':$v.item.frecuencia.$error && $v.item.frecuencia.$dirty}">
                            <div class="input-group-append">
                                <select class="form-control form-control-sm form-control-solid" v-model="item.unidad">
                                    <option value="horas" >Hora(s)</option>
                                    <option value="dias">Dia(s)</option>
                                    <option value="minutos">Minuto(s)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    
                    <div class="form-group col-md-12 col-lg-3 col-sm-12 col-xs-12">
                        <label class="form-label small mb-0">Por <small class="text-danger">*</small></label>
                        <div class="input-group">
                            <input type="number" class="form-control form-control-sm" v-model="item.duracion" :class="{'is-invalid':$v.item.duracion.$error && $v.item.duracion.$dirty}">
                            <div class="input-group-append">
                                <select class="form-control form-control-sm form-control-solid" v-model="item.duracion_unity" :class="{'is-invalid':$v.item.duracion_unity.$error && $v.item.duracion_unity.$dirty}">
                                    <option value="horas" >Hora(s)</option>
                                    <option value="dias">Dia(s)</option>
                                    <option value="minutos">Minuto(s)</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="form-group col-md-12 col-lg-2 col-sm-12 col-xs-12">
                        <label class="form-label small mb-0">Via adm. <small class="text-danger">*</small></label>
                        <select class="form-control form-control-sm" v-model="item.route_administration"
                        :class="{'is-invalid':$v.item.route_administration.$error && $v.item.route_administration.$dirty}">
                            <option :value="administration" v-for="(administration,index) in routes_of_administration" :key="index">{{administration}}</option>
                        </select>
                    </div>
                    <div class="form-group col-sm-12 col-xs-12 col-md-12 col-lg-2">
                        <label class="form-label small mb-0">
                            Total 
                            <a class="pop_help" data-content="El sistema calcula el total de dosis de forma automatica en los casos de medicamentos como(TABLETA,OVULOS,CAPSULAS,AMPOLLA,UNIDAD), por favor verifique que el total sea correcto. si no es asi, entonces edite este valor"
                               data-toggle="popover"
                               tabindex="0"
                               title="Total">
                                <i class="fas fa-question-circle"></i>
                            </a>
                        </label>
                        <input v-model="calculateTotal"  
                            class="form-control form-control-sm"
                            type="number"
                        />
                    </div>

                    <div class="form-group col-md-12 col-sm-12 col-lg-8">
                        <label class="form-label mb-0">Indicaciones <small class="text-danger">*</small></label>
                        <input type="text" class="form-control form-control-sm" v-model="item.indications" :class="{'is-invalid':$v.item.indications.$error && $v.item.indications.$dirty}">
                    </div>
                    <div class="mt-3 py-2 col-md-2 col-lg-2 col-sm-12 col-xs-12  ">
                        <button class="btn btn-primary btn-sm" @click="agregarServicio()">
                            <i class="fas fa-plus"></i>&nbsp;Agregar
                        </button>
                    </div>
                </div>
               
                
                <div class="table-responsive">
                    <table
                        class="table table-borderless mb-0 table-sm table-services"
                    >
                        <caption class="sr-only">
                            Medicamento
                        </caption>
                        <thead class="border-bottom">
                        <tr class="small text-uppercase text-muted">
                            <th scope="col">Medicamento</th>
                            <th scope="col">Dosis</th>
                            <th scope="col">U. Medida</th>
                            <th scope="col">Cada</th>
                            <th scope="col">Duracion</th>
                            <th scope="col">Total</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="border-bottom"
                                v-for="(item,index) in items" :key="item.id"
                            >
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.servicio.codigo }}
                                        {{ item.servicio.descripcion }}
                                        <div class="small text-muted d-none d-md-block">
                                            F. farmaceutica: {{ item.presentation }}
                                        </div>
                                        <div class="small text-muted d-none d-md-block">
                                            Via admin : {{ item.route_administration }}
                                        </div>
                                        <div class="small text-muted d-none d-md-block">
                                            Indicaciones : {{ item.indications }}
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.dosis }} </td>
                                <td>{{ item.unity }} </td>
                                <td>{{ item.frecuencia }} {{item.unidad}}</td>
                                <td>{{ item.duracion }} {{ item.duracion_unity }}</td>
                                <td>{{ item.total }} {{item.presentation}}</td>
                                <td>
                                    <button class="btn btn-transparent rounded-circle"
                                        @click="removerServicio(index)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group mt-4">
                    <label  class="form-label">Observaciones </label>
                    <div class="">
                        <textarea  v-model="observaciones" class="form-control" ></textarea>
                    </div>
                </div>
                <button class="btn btn-success" @click="guardar()">
                    <i class="fas fa-save"></i>&nbsp;Guardar
                </button>
            </div>
        </div>
    </main>
</template>
<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import formulaMedicaService from '../../../../services/formulaMedicaService';
import modalFormulasComponent from './modalFormulasComponent';
import $ from 'jquery';
import {required } from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';

export default {
    props:['idHistoria','usuario'],
    components: {vSelectServicio,modalFormulasComponent},
     
    data() {
        return{
            observaciones:'',
            item:{
                servicio:{},
                dosis:'',
                unity:'',
                route_administration:'',
                presentation:'',
                indications:'Ninguna',
                frecuencia:'',
                duracion:'',
                total:0,
                unidad:'horas',
                duracion_unity:'horas'
            },
            items: [],
            formulas: [],
            presentations:[
                'TABLETA','JARABE','CREMA','CAPSULA','AMPOLLA','SUSPENSION','EMULSION','SUPOSITORIO',
                'OVULO','CREMA','GEL','INHALADOR','GOTAS','NEBULIZADOR','SOLUCION','UNIDAD','NO APLICA'
            ],
            measurement_units:[
                'CC','TAB',
                'GOTA(S)','AMPOLLA(S)','PUFF(S)',
                'GRAMO(S)','CAPSULA(S)','UNIDAD(ES)',
                'OVULO(S)','Mcg','APLICACION','NA','ML'
            ],
            routes_of_administration:[
                'ORAL','TOPICA','OFTALMICA','SUBLINGUAL','TRANSDERMICA','OTICA','INTRANASAL',
                'INHALATORIA','RECTAL','VAGINAL','INTRAMUSCULAR','SUBCUTANEA','INTRAVENOSA','NO APLICA'
            ],
            formasUnidades: {
                'TABLETA':'TAB',
                'JARABE':'ML',
                'CREMA':'APLICACION',
                'CAPSULA':'CAPSULA(S)',
                'AMPOLLA':'AMPOLLA(S)',
                'SUSPENSION':'CC',
                'EMULSION':'CC',
                'SUPOSITORIO':'TAB',
                'OVULO':'OVULO(S)',
                'GEL':'APLICACION',
                'INHALADOR':'PUFF(S)',
                'GOTAS':'GOTA(S)',
                'NEBULIZADOR':'PUFF(S)',
                'SOLUCION':'CC',
                'UNIDAD':'UNIDAD(ES)',
                'NO APLICA':'NA'
            },
            tipo_historia:'',
        }
    },
    validations (){
        return {
            item:{ 
               dosis:{required},
               frecuencia:{required},
               duracion:{required},
               indications:{required},
               duracion_unity:{required},
               presentation:{required},
               unity:{required},
               route_administration:{required},
               servicio:{required},
            }
        }
    },
    methods: {
        agregarServicio() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.item.total=this.calculateTotal;
            this.items.push({...this.item});
            this.item={};
            this.$v.item.$reset();
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async guardar() {
            try {
                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar por lo menos un medicamento'
                    });
                    return;
                }
                const formula = {
                    id_historia: this.idHistoria,
                    observaciones: this.observaciones,
                    //interna: this.interna?1:0,
                    item_formulas: this.items,
                };
                
                this.LoaderSpinnerShow();
                await formulaMedicaService.store(formula);
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

                this.limpiarCampos();

            }catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async historialFormulas() {
            $('#modalFormula').modal('show');
            const response = await formulaMedicaService.show(this.idHistoria);
            this.formulas = response.data; 
        },
        limpiarCampos(){
            this.observaciones='';
            this.items=[];
            this.item={};
            this.servicios={};
        },
        getFactorTiempo(unidad) {
            switch (unidad) {
                case 'horas':
                    return 1;
                case 'dias':
                    return 24;
                case 'minutos':
                    return 1/60;
                default:
                    return 1;
            }
        },
        isPresentationValid() {
            const validPresentations = ['TABLETA', 'OVULO','CAPSULA','AMPOLLA','UNIDAD'];
            return validPresentations.includes(this.item.presentation);
        },
        actualizarUnidadMedida() {
            if (this.item.presentation in this.formasUnidades) {
                this.item.unity = this.formasUnidades[this.item.presentation];
            }
        }
    },
    computed:{
        calculateTotal:{
            get(){
                if(this.isPresentationValid()){
                    const factorFrecuencia = this.getFactorTiempo(this.item.unidad);
                    const factorDuracion = this.getFactorTiempo(this.item.duracion_unity);

                    const duracionEnHoras = this.item.duracion * factorDuracion;
                    const frecuenciaEnHoras = this.item.frecuencia * factorFrecuencia;

                    if (duracionEnHoras <= 0 || frecuenciaEnHoras <= 0) {
                        return 0;
                    }

                    const totalDosis = Math.floor(duracionEnHoras / frecuenciaEnHoras) * this.item.dosis;
                    return isNaN(totalDosis) ? 0 : totalDosis;

                }else{
                   return this.item.total;
                }
            },
            set(newValue){
                this.item.total=newValue
            }
        }
    },
    watch:{
        'item.presentation': {
        handler: 'actualizarUnidadMedida',
        immediate: true
    }
    },
    created(){
        this.tipo_historia=this.$route.params.codigo;
        this.$nextTick(() => $('.pop_help').popover());
        this.actualizarUnidadMedida();
        //this.interna=this.tipo_historia==="URG"?true:false
    }
   
}
</script>
<style scoped>
 /*toogle checkbox*/
 *,
 *:before,
 *:after {
   box-sizing: border-box;
 }
 
 body {
   font-family: -apple-system, ".SFNSText-Regular", "Helvetica Neue", "Roboto", "Segoe UI", sans-serif;
 }
 
 .toggle {
   cursor: pointer;
   display: inline-block;
 }
 
 .toggle-switch {
   display: inline-block;
   background: #ccc;
   border-radius: 16px;
   width: 52px;
   height: 26px;
   position: relative;
   vertical-align: middle;
   transition: background 0.25s;
 }
 .toggle-switch:before, .toggle-switch:after {
   content: "";
 }
 .toggle-switch:before {
   display: block;
   background: linear-gradient(to bottom, #fff 0%, #eee 100%);
   border-radius: 50%;
   box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
   width: 18px;
   height: 18px;
   position: absolute;
   top: 4px;
   left: 4px;
   transition: left 0.25s;
 }
 .toggle:hover .toggle-switch:before {
   background: linear-gradient(to bottom, #fff 0%, #fff 100%);
   box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.5);
 }
 .toggle-checkbox:checked + .toggle-switch {
   background: #0872fd;
 }
 .toggle-checkbox:checked + .toggle-switch:before {
   left: 30px;
 }
 
 .toggle-checkbox {
   position: absolute;
   visibility: hidden;
 }
 
 .toggle-label {
   margin-left: 5px;
   position: relative;
   top: 2px;
   font-size: 18px;
 }
 .table-services {
    font-size: 0.9rem;
}
</style>